import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import Tile from '../../components/Tile/Tile';
import Alert from '../../components/Alert/Alert';

import Crane from '../../svgs/barrier-greyBg.svg';
import Megaphone from '../../svgs/megaphone.svg';

const NewHomeDashboardPage = () => {
	const intl = useIntl();
	return (
		<Layout header={<span className='bold'>{intl.formatMessage({ id: 'complaints.title' })}</span>}>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'complaints.title' })} />
			<Container>
				<Row>
					<Col>
						<Alert type='warning'>
							<FormattedMessage id='fileComplaint.canadapost1' />{' '}
							<a href='mailto:complaints@hcraontario.ca'>complaints@hcraontario.ca</a>{' '}
							<FormattedMessage id='fileComplaint.canadapost2' />{' '}
							<a href='mailto:investigations@hcraontario.ca'>investigations@hcraontario.ca</a>{''}
							<FormattedMessage id='fileComplaint.canadapost3' />{' '}
							<a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>{' '}
							<FormattedMessage id='fileComplaint.canadapost4' />{' '}
							<a href='tel:1-800-582-7994'>1-800-582-7994</a>{' '}
							<FormattedMessage id='fileComplaint.canadapost5' />
						</Alert>
					</Col>
				</Row>
				<Row>
					<Col>
						<p>
							<FormattedMessage id='fileComplaint.contentA' />
						</p>
						<p>
							<FormattedMessage id='fileComplaint.contentB' />{' '}
							<Link to='/licensing-compliance/licensed-builder-complaint/'>
								<FormattedMessage id='fileComplaint.contentBii' />
							</Link>
							.
							<br />
							<FormattedMessage id='fileComplaint.contentC' />{' '}
							<Link to='/licensing-compliance/illegal-building-complaint/'>
								<FormattedMessage id='fileComplaint.contentCii' />
							</Link>
							.
						</p>
						<p>
							<FormattedMessage id='fileComplaint.contentD' />{' '}
							<Link to='/licensing-compliance/complaints-process/'>
								<FormattedMessage id='fileComplaint.contentDii' />
							</Link>
							.
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col xl={6} md={6} sm={12}>
						<Tile
							icon={<Megaphone />}
							link={{
								text: intl.formatMessage({
									id: 'complaintForms.licensedLink'
								}),
								path: '/licensing-compliance/licensed-builder-complaint/'
							}}
						/>
					</Col>

					<Col xl={6} md={6} sm={12}>
						<Tile
							icon={<Crane />}
							link={{
								text: intl.formatMessage({
									id: 'complaintForms.illegalLink'
								}),
								path: '/licensing-compliance/illegal-building-complaint/'
							}}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default NewHomeDashboardPage;
